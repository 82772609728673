<script>
  import { Router, Route } from 'svelte-routing';

  import Consent from './routes/Consent.svelte';

  import Modules from './routes/_Modules.svelte';
  import SystemDashboard from './routes/_SystemDashboard.svelte';
  import CreateInstitution from './routes/institutions/Create.svelte';
  import CreateModule from './routes/modules/Create.svelte';
  import EditInstitution from './routes/institutions/edit/[institutionId].svelte';
  import EditModule from './routes/modules/edit/[moduleId].svelte';
  import ViewInstitution from './routes/institutions/view/[institutionId].svelte';
  import ViewModule from './routes/modules/view/[moduleId].svelte';

  import Dashboard from './routes/_Dashboard.svelte';
  import HeadsetLogin from './routes/qr/HeadsetLogin.svelte';
  import CalibrateRoom from './routes/qr/CalibrateRoom.svelte';

  import SavedSessions from './routes/sessions/List.svelte';
  import CreateSession from './routes/sessions/Create.svelte';
  import CreateSessionSelectApp from './routes/sessions/CreateSelectApp.svelte';
  import CreateSessionSelectPlan from './routes/sessions/CreateSelectPlan.svelte';
  import ViewSession from './routes/sessions/view/[sessionId].svelte';
  import SessionLobby from './routes/sessions/lobby/[sessionId].svelte';

  import ViewSessionPlan from './routes/sessions/plans/view/[sessionId].svelte';
  import SessionPlans from './routes/sessions/plans/List.svelte';

  import UserManagement from './routes/users/List.svelte';
  import AddUser from './routes/users/Add.svelte';
  import UploadUsers from './routes/users/Upload.svelte';
  import EditUser from './routes/users/edit/[accountId].svelte';
  import UserDetails from './routes/users/view/[accountId].svelte';

  import Classes from './routes/classes/List.svelte';
  import CreateClass from './routes/classes/Create.svelte';
  import EditClass from './routes/classes/edit/[classId].svelte';
  import ViewClass from './routes/classes/view/[classId].svelte';

  import Departments from './routes/departments/List.svelte';
  import CreateDepartment from './routes/departments/Create.svelte';
  import EditDepartment from './routes/departments/edit/[departmentId].svelte';
  import ViewDepartment from './routes/departments/view/[departmentId].svelte';

  import InstitutionAdminSupport from './routes/_InstitutionAdminSupport.svelte';
  import Support from './routes/Support.svelte';
  import Licenses from './routes/licenses/List.svelte';
  import ViewLicense from './routes/licenses/view/[institutionId].svelte';
  import EditLicense from './routes/licenses/edit/[licensedClientApp].svelte';
  import MyAccount from './routes/my-account/View.svelte';
  import EditMyAccount from './routes/my-account/Edit.svelte';
  import Logout from './routes/Logout.svelte';

  import NotFound from './routes/_NotFound.svelte';
  import Login from './routes/_Login.svelte';
  import ForgotPassword from './routes/ForgotPassword.svelte';
  import ResetPassword from './routes/ResetPassword.svelte';
  import AccountInactive from './routes/AccountInactive.svelte';
  import EmailVerification from './routes/EmailVerification.svelte';

  import AppSettings from './routes/AppSettings.svelte';
  import HowToRegister from './routes/HowToRegister.svelte';
  import FirstTimeExperience from './routes/FirstTimeExperience.svelte';
  import TurnOnNotifications from './routes/TurnOnNotifications.svelte';

  import TermsOfUse from './routes/TermsOfUse.svelte';
  import PrivacyPolicy from './routes/PrivacyPolicy.svelte';
  import Resources from './routes/Resources.svelte';

  import Conversations from './routes/Conversations.svelte';

  import {
    isMasquerading,
    role,
    account,
    isAuthenticated,
    snackbar,
    snackbarMessage,
    institutionIdStore,
    urlHistory,
  } from './stores/core-store';
  import { realInstitutionStore } from './stores/institutions-store';
  import Subheader from './components/Subheader.svelte';
  import Snackbar, { Actions, Label } from '@smui/snackbar';
  import IconButton from '@smui/icon-button';
  import CompleteRegistration from './routes/CompleteRegistration.svelte';
  import MobileNavigation from './components/MobileNavigation.svelte';
  import DesktopSideNavigation from './components/DesktopSideNavigation.svelte';
  import { AccountRole } from './util/api/accounts';
  import Footer from './components/Footer.svelte';
  import { unhandledRejectionHandler } from './util/unhandled-rejection-handler';
  import OnNavigateHooks from './components/OnNavigateHooks.svelte';
  import LogoutDialog from './components/my-account/LogoutDialog.svelte';
  import MasqueradeHeader from './components/MasqueradeHeader.svelte';
  import Cookies from 'js-cookie';
  import { getQueryParameter } from './util/util';
  import {
    getClientAppId,
    isHoloHuman,
    isLegacyHoloPatient,
    isUnity,
    sendUnityGetEnvironmentCredentialsMessage,
    subscribeToUnityMessage,
    UnityAction,
  } from './util/unity';
  import { setupAutomaticBackgroundDownloads } from './util/download-manager';
  import { fetchInstitutionLeaf } from './util/api/institutions';
  import { onDestroy, onMount } from 'svelte';
  import PlatformAppSettings from './routes/PlatformAppSettings.svelte';
  import LegacyHoloHumanAppSettings from './routes/LegacyHoloHumanAppSettings.svelte';
  import SwitchServer from './routes/SwitchServer.svelte';
  import { globalHistory } from 'svelte-routing/src/history';

  window.Cookies = Cookies;

  // Default attributes applied to all calls to Cookies.
  Cookies.defaults = {
    sameSite: 'lax',
  };

  // Alternative way for the unity environment to identify itself.
  // Sometimes setting the cookie from UniWebView does not get set.
  const unity = getQueryParameter('unity');
  if (unity) {
    Cookies.set('gigxr-unity', 1);
  }

  // Identifies whether developer-specific features should be enabled.
  const developer = getQueryParameter('developer');
  if (developer) {
    Cookies.set('gigxr-developer', 1);
  }

  // Identifies whether platform-specific features should be enabled.
  // Deprecated! This will be removed in the future.
  const platform = getQueryParameter('platform');
  if (platform) {
    Cookies.set('gigxr-platform', 1);
  }

  // Identifies which app the GMS is embedded in, if applicable.
  const clientAppId = getQueryParameter('clientAppId');
  if (clientAppId) {
    Cookies.set('gigxr-clientAppId', clientAppId);
  }

  /**
   * Set from Unity.
   * Key = email/username
   * Value = password
   * @type Map<string, string>
   * */
  window.environmentCredentials = new Map();

  const unsubscribeUnityEnvironmentCredentials = subscribeToUnityMessage(
    UnityAction.ENVIRONMENT_CREDENTIALS,
    (payload) => {
      window.environmentCredentials = new Map(payload);
    },
  );

  sendUnityGetEnvironmentCredentialsMessage();

  $: {
    if ($isMasquerading) {
      role.set('InstitutionAdmin');
    } else {
      let a = $account;
      role.set(a ? a.accountRole : 'Invalid');
    }
  }

  // This reactive block is to store a copy of the institutionName on an authentication change.
  $: if ($isAuthenticated) {
    updateRealInstitutionStore();
  } else {
    clearRealInstitutionStore();
  }

  async function updateRealInstitutionStore() {
    if ($institutionIdStore) {
      $realInstitutionStore = await fetchInstitutionLeaf($institutionIdStore);
    }
  }

  async function clearRealInstitutionStore() {
    $realInstitutionStore = null;
  }

  setupAutomaticBackgroundDownloads();

  let unsubscribeUnitySnackBarMessage;
  let unsubscribeGlobalHistory;
  onMount(() => {
    unsubscribeUnitySnackBarMessage = subscribeToUnityMessage(UnityAction.SNACKBAR_MESSAGE, (payload) => {
      const message = payload;
      snackbarMessage.set(message);
      $snackbar.open();
    });
    unsubscribeGlobalHistory = globalHistory.listen(({ location }) => {
      urlHistory.update((currentHistory) => {
        return [...currentHistory, location.pathname];
      });
    });
  });

  onDestroy(() => {
    unsubscribeUnityEnvironmentCredentials();
    unsubscribeUnitySnackBarMessage();
    unsubscribeGlobalHistory();
  });
</script>

<svelte:window on:unhandledrejection={unhandledRejectionHandler} />

<Router>
  {#if $isAuthenticated}
    <MasqueradeHeader />
    <MobileNavigation />
    <DesktopSideNavigation />
    <div class="content-wrapper">
      <Subheader />
      <main role="main">
        <Route path="logout">
          <Logout />
        </Route>
        <Route path="licenses/list">
          <Licenses />
        </Route>
        <Route path="conversations">
          <Conversations />
        </Route>

        {#if $role === AccountRole.GIGXR_ADMIN}
          <!------------------>
          <!-- Institutions -->
          <!------------------>
          <Route path="institutions/view/:institutionId" let:params>
            <ViewInstitution institutionId={params.institutionId} />
          </Route>
          <!-------------->
          <!-- Licenses -->
          <!-- ----------->
          <Route path="licenses/view/:institutionId" let:params>
            <ViewLicense institutionId={params.institutionId} />
          </Route>
          <Route path="licenses/edit/:licensedClientApp" let:params>
            <EditLicense licensedClientApp={params.licensedClientApp} />
          </Route>
          <Route path="institutions/edit/:institutionId" let:params>
            <EditInstitution institutionId={params.institutionId} />
          </Route>
          <Route path="/institutions/create">
            <CreateInstitution />
          </Route>
          <Route path="/modules">
            <Modules />
          </Route>
          <Route path="modules/view/:moduleId" let:params>
            <ViewModule moduleId={params.moduleId} />
          </Route>
          <Route path="/modules/create">
            <CreateModule />
          </Route>
          <Route path="modules/edit/:moduleId" let:params>
            <EditModule moduleId={params.moduleId} />
          </Route>
          <!------------------->
          <!-- Session Plans -->
          <!------------------->
          <Route path="sessions/plans/view/:sessionId" let:params>
            <ViewSessionPlan sessionId={params.sessionId} />
          </Route>
          <Route path="sessions/plans/list">
            <SessionPlans />
          </Route>
          <Route path="/">
            <SystemDashboard />
          </Route>
          <!----------->
          <!-- Other -->
          <!----------->
          <Route path="resources">
            <Resources showRole="admin" />
          </Route>

          <!----------->
          <!-- Users -->
          <!-- -------->
          <Route path="users/view/:accountId" let:params>
            <UserDetails accountId={params.accountId} />
          </Route>
          <Route path="users/edit/:accountId" let:params>
            <EditUser accountId={params.accountId} />
          </Route>
          <Route path="users/add">
            <AddUser />
          </Route>
          <Route path="users/list">
            <UserManagement />
          </Route>
          <Route path="users/upload">
            <UploadUsers />
          </Route>
        {:else}
          <!-------------->
          <!-- Sessions -->
          <!-------------->
          <Route path="sessions/view/:sessionId" let:params>
            <ViewSession sessionId={params.sessionId} />
          </Route>
          <Route path="sessions/create" let:params>
            <CreateSession />
          </Route>
          <Route path="sessions/create/:clientAppId" let:params>
            <CreateSession clientAppId={params.clientAppId} />
          </Route>
          <Route path="sessions/create-session-select-app">
            <CreateSessionSelectApp />
          </Route>
          <Route path="sessions/create-session-select-plan/:clientAppId" let:params>
            <CreateSessionSelectPlan clientAppId={params.clientAppId} />
          </Route>
          <Route path="sessions/list">
            <SavedSessions />
          </Route>
          <Route path="sessions/lobby/:sessionId" let:params>
            <SessionLobby sessionId={params.sessionId} />
          </Route>

          <!------------------->
          <!-- Session Plans -->
          <!------------------->
          <Route path="sessions/plans/view/:sessionId" let:params>
            <ViewSessionPlan sessionId={params.sessionId} />
          </Route>
          <Route path="sessions/plans/list">
            <SessionPlans />
          </Route>

          <!----------->
          <!-- Users -->
          <!-- -------->
          <Route path="users/view/:accountId" let:params>
            <UserDetails accountId={params.accountId} />
          </Route>
          <Route path="users/edit/:accountId" let:params>
            <EditUser accountId={params.accountId} />
          </Route>
          <Route path="users/add">
            <AddUser />
          </Route>
          <Route path="users/list">
            <UserManagement />
          </Route>
          <Route path="users/upload">
            <UploadUsers />
          </Route>

          <!------------->
          <!-- Classes -->
          <!-- ---------->
          <Route path="classes/view/:classId" let:params>
            <ViewClass classId={params.classId} />
          </Route>
          <Route path="classes/edit/:classId" let:params>
            <EditClass classId={params.classId} />
          </Route>
          <Route path="classes/create">
            <CreateClass />
          </Route>
          <Route path="classes/list">
            <Classes />
          </Route>

          <!----------------->
          <!-- Departments -->
          <!-- -------------->
          <Route path="departments/view/:departmentId" let:params>
            <ViewDepartment departmentId={params.departmentId} />
          </Route>
          <Route path="departments/edit/:departmentId" let:params>
            <EditDepartment departmentId={params.departmentId} />
          </Route>
          <Route path="departments/create">
            <CreateDepartment />
          </Route>
          <Route path="departments/list">
            <Departments />
          </Route>

          <!----------->
          <!-- Other -->
          <!-- -------->
          <Route path="resources">
            {#if $role === AccountRole.STUDENT}
              <Resources showRole="student" />
            {:else}
              <Resources showRole="admin" />
            {/if}
          </Route>
          <Route path="support">
            {#if $role === AccountRole.INSTITUTION_ADMIN}
              <InstitutionAdminSupport />
            {:else}
              <Support />
            {/if}
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        {/if}

        <Route path="switch">
          <SwitchServer />
        </Route>

        <!---------------->
        <!-- My Account -->
        <!-- ------------->
        <Route path="my-account/view">
          <MyAccount />
        </Route>
        <Route path="my-account/edit" let:params>
          <EditMyAccount />
        </Route>

        <!----------->
        <!-- Unity -->
        <!-- -------->
        {#if isUnity()}
          <Route path="/app-settings">
            {#if isLegacyHoloPatient(getClientAppId())}
              <AppSettings />
            {:else if isHoloHuman(getClientAppId())}
              <LegacyHoloHumanAppSettings />
            {:else}
              <PlatformAppSettings />
            {/if}
          </Route>
        {/if}

        <Route>
          <NotFound />
        </Route>
      </main>
    </div>
    <div class="content-wrapper-full-width-mobile">
      <!-------->
      <!-- QR -->
      <!-- ----->
      <Route path="qr/headset-login">
        <HeadsetLogin />
      </Route>
      <Route path="qr/calibrate-room">
        <CalibrateRoom />
      </Route>
    </div>
    <Footer />
    <LogoutDialog />
  {:else}
    <!--------------------------->
    <!-- Unauthenticated pages -->
    <!-- ------------------------>
    <main role="main" class="unauthenticated-pages">
      <Route path="/consent/:token" let:params>
        <Consent token={params.token} />
      </Route>
      <Route path="/complete-registration/:token" let:params>
        <CompleteRegistration token={params.token} />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password/:token" let:params>
        <ResetPassword token={params.token} />
      </Route>
      <Route path="/email-verification/:token" let:params>
        <EmailVerification token={params.token} />
      </Route>

      <!----------->
      <!-- Unity -->
      <!-- -------->
      {#if isUnity()}
        <Route path="/how-to-register">
          <HowToRegister />
        </Route>
        <Route path="/first-time-experience">
          <FirstTimeExperience />
        </Route>
        <Route path="/turn-on-notifications">
          <TurnOnNotifications />
        </Route>
      {/if}

      <Route path="/account-inactive">
        <AccountInactive />
      </Route>
      <Route path="/terms-of-use">
        <TermsOfUse />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route>
        <Login />
      </Route>
    </main>
  {/if}
  <OnNavigateHooks />
</Router>

<Snackbar bind:this={$snackbar} labelText={$snackbarMessage}>
  <Label />
  <Actions>
    <IconButton id="close-snackbar-button" class="material-icons" title="Dismiss">close</IconButton>
  </Actions>
</Snackbar>

<style>
  .content-wrapper-full-width-mobile {
    max-width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .unauthenticated-pages {
      display: grid;
      place-items: center;
      height: 100vh;
    }
  }
</style>
