<script>
  import { onMount, onDestroy } from 'svelte';
  import { link } from 'svelte-routing';
  import { title, errorMessage, breadcrumbPaths } from '../stores/core-store';
  import { slide } from 'svelte/transition';
  import { authenticatedGet } from '../util/api.js';
  import SavedSessionCard from '../components/sessions/SavedSessionCard.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import AddSessionButton from '../components/sessions/AddSessionButton.svelte';
  import PageControlsRow from '../components/PageControlsRow.svelte';
  import MobileListPageControls from '../components/MobileListPageControls.svelte';
  import DesktopListPageControls from '../components/DesktopListPageControls.svelte';
  import Button, { Label, Icon } from '@smui/button';
  import { navigate } from 'svelte-routing';
  import CreateSessionButton from '../components/sessions/CreateSessionButton.svelte';
  import HeadsetLoginButton from '../components/dashboard/HeadsetLoginButton.svelte';
  import TwoColumnSection from '../components/TwoColumnSection.svelte';
  import LoadingView from '../components/LoadingView.svelte';
  import ListPageNoResultsMessage from '../components/ListPageNoResultsMessage.svelte';
  import { GmsError } from '../util/errors';
  import { GENERIC_ERROR_MESSAGE } from '../util/constants';
  import { fetchNotifications } from '../util/api/notifications';
  import DashboardNotificationList from '../components/dashboard/DashboardNotificationList.svelte';
  import AvailableSessionCard from '../components/sessions/AvailableSessionCard.svelte';
  import AvailableSessionCardContainer from '../components/sessions/AvailableSessionCardContainer.svelte';
  import GigXrAccordian from '../components/GigXrAccordian.svelte';
  import { fetchLoginQrCode } from '../util/api/accounts';
  import { generateQrCodeImageData } from '../util/qr-codes';
  import CircularProgress from '../components/CircularProgress.svelte';
  import { fetchAvailableSessions } from '../util/api/sessions';
  import ContentTabs from '../components/ContentTabs.svelte';
  import QrCodeTab from '../components/headset-login/QRCodeTab.svelte';
  import NumericCodeTab from '../components/headset-login/NumericCodeTab.svelte';

  title.set('Dashboard');
  breadcrumbPaths.set([]);

  const INITIAL_TIMER_VALUE = 5 * 60;

  let loading = true;
  let headsetLoginQrLoading = true;
  let notifications = [];
  let availableSessions = [];
  let savedSessions = [];
  let sessionPlans = [];
  let timer = INITIAL_TIMER_VALUE;
  let intervalId;
  let qrCode = '';
  let timerStartTime = 0;

  // Defaults to a blank image
  let headsetLoginImageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  $: notificationUnreadCount = notifications.filter((n) => !n.read).length;

  onMount(async () => {
    const AVAILABLE_SESSIONS_POLL_DELAY = 5000;
    await Promise.all([refreshDashboard(), pollAvailableSessions(AVAILABLE_SESSIONS_POLL_DELAY)]);

    loading = false;
    startTimer();
  });

  let availableSessionsTimerId;

  async function pollAvailableSessions(delay) {
    availableSessions = await fetchAvailableSessions();

    availableSessionsTimerId = setTimeout(async () => {
      await pollAvailableSessions(delay);
    }, delay);
  }

  async function refreshDashboard() {
    let dashboardData;
    [dashboardData, notifications] = await Promise.all([fetchDashboardData(), fetchNotifications()]);
    savedSessions = dashboardData.recentSessions.slice(0, 3);
    sessionPlans = dashboardData.recentSessionPlans.slice(0, 3);
  }

  async function fetchDashboardData() {
    const response = await authenticatedGet('/dashboard');
    if (!response.ok) {
      throw new GmsError(`Error fetching dashboard data! ${GENERIC_ERROR_MESSAGE}`);
    }
    const json = await response.json();
    return json.data;
  }

  function startTimer() {
    timerStartTime = Date.now();
    intervalId = setInterval(async () => {
      const elapsed = Math.floor((Date.now() - timerStartTime) / 1000);
      const remainingTime = INITIAL_TIMER_VALUE - elapsed;

      if (remainingTime > 0) {
        timer = remainingTime;
      } else {
        loading = true;
        timerStartTime = Date.now();
        timer = INITIAL_TIMER_VALUE;
        qrCode = await fetchLoginQrCode('');
        headsetLoginImageSrc = await generateQrCodeImageData(String(qrCode));
        loading = false;
      }
    }, 1000);
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  }

  async function loadHeadsetLoginHandler(event) {
    const payload = '';
    qrCode = await fetchLoginQrCode(payload);
    headsetLoginImageSrc = await generateQrCodeImageData(String(qrCode));
    headsetLoginQrLoading = false;
    timer = INITIAL_TIMER_VALUE;
  }

  onDestroy(() => {
    clearTimeout(availableSessionsTimerId);
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
</script>

<div class="mobile-dashboard">
  <GigXrAccordian id="mobile-headset-login-button" header="Headset Login" on:click={() => loadHeadsetLoginHandler()}>
    <div class="mobile-dashboard__headset-qr-wrapper">
      {#if headsetLoginQrLoading}
        <CircularProgress />
      {:else}
        <ContentTabs
          items={[{ label: 'QR code', component: QrCodeTab }, { label: 'Numeric Code', component: NumericCodeTab }]}
          props={{ imageSrc: headsetLoginImageSrc, loading: loading || headsetLoginQrLoading, withSession: false, qrCode, timer: formatTime(timer) }}
        />
      {/if}
    </div>
  </GigXrAccordian>

  <GigXrAccordian
    id="mobile-notifications-button"
    header="Notifications {!loading && notificationUnreadCount > 0 ? `(${notificationUnreadCount})` : ''}"
  >
    {#if loading}
      <LoadingView withMargin />
    {:else if notifications.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no notifications to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <DashboardNotificationList bind:notifications />
    {/if}
  </GigXrAccordian>

  <GigXrAccordian id="mobile-available-sessions-button" header="Active Sessions" open={true}>
    {#if loading}
      <LoadingView withMargin />
    {:else if availableSessions.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no active sessions to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <AvailableSessionCardContainer>
        {#each availableSessions as session (session.sessionId)}
          <AvailableSessionCard {session} />
        {/each}
      </AvailableSessionCardContainer>
    {/if}
  </GigXrAccordian>

  <GigXrAccordian id="mobile-recent-saved-sessions-button" header="Recent Saved Sessions">
    {#if loading}
      <LoadingView withMargin />
    {:else if savedSessions.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no saved sessions to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <div class="mobile-sessions-button">
        <CreateSessionButton />
      </div>
      <div class="recent-sessions">
        {#each savedSessions as session (session.sessionId)}
          <SavedSessionCard {session} on:GigXr:SessionCard:addToSessionPlan={refreshDashboard} />
        {/each}
      </div>

      {#if savedSessions.length > 0}
        <div class="mobile-sessions-link">
          <a
            id="mobile-view-more-sessions-link"
            href="/sessions/list"
            class="gigxr-link dashboard-view-more-button"
            use:link
          >View More</a>
        </div>
      {/if}
    {/if}
  </GigXrAccordian>

  <GigXrAccordian id="mobile-recent-session-plans-button" header="Recent Session Plans">
    {#if loading}
      <LoadingView withMargin />
    {:else if sessionPlans.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no session plans to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <div class="recent-sessions">
        {#each sessionPlans as plan (plan.sessionId)}
          <SavedSessionCard session={plan} />
        {/each}
      </div>

      {#if sessionPlans.length > 0}
        <div class="mobile-sessions-link">
          <a
            id="mobile-view-more-session-plans-link"
            href="/sessions/plans/list"
            class="gigxr-link dashboard-view-more-button"
            use:link
          >View More</a>
        </div>
      {/if}
    {/if}
  </GigXrAccordian>
</div>

<div class="desktop-dashboard">
  <PrimaryContent>
    <div class="headset-login">
      <HeadsetLoginButton />
    </div>
  </PrimaryContent>

  <PrimaryContent>
    <DashboardNotificationList bind:notifications />
  </PrimaryContent>

  <SecondaryBackgroundWrapper>
    <PrimaryContent>
      <PageControlsRow>
        <div slot="left">
          <h2>Active Sessions</h2>
        </div>
        <div slot="right" />
      </PageControlsRow>

      {#if loading}
        <LoadingView withMargin />
      {:else if availableSessions.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no active sessions to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <AvailableSessionCardContainer>
          {#each availableSessions as session (session.sessionId)}
            <AvailableSessionCard {session} />
          {/each}
        </AvailableSessionCardContainer>
      {/if}

      <div class="sessions-link sessions-link--available-sessions" />

      <PageControlsRow>
        <div slot="left">
          <h2>Recent Saved Sessions</h2>
        </div>
        <div slot="right">
          <CreateSessionButton />
        </div>
      </PageControlsRow>

      {#if loading}
        <LoadingView withMargin />
      {:else if savedSessions.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no saved sessions to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <div class="recent-sessions">
          {#each savedSessions as session (session.sessionId)}
            <SavedSessionCard {session} on:GigXr:SessionCard:addToSessionPlan={refreshDashboard} />
          {/each}
        </div>
      {/if}

      <div class="sessions-link sessions-link--saved-sessions">
        {#if savedSessions.length > 0}
          <a
            id="desktop-view-more-sessions-link"
            href="/sessions/list"
            class="gigxr-link dashboard-view-more-button"
            use:link
          >View More</a>
        {/if}
      </div>

      <PageControlsRow>
        <div slot="left">
          <h2>Recent Session Plans</h2>
        </div>
        <div slot="right" />
      </PageControlsRow>

      {#if loading}
        <LoadingView withMargin />
      {:else if sessionPlans.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no session plans to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <div class="recent-sessions">
          {#each sessionPlans as plan (plan.sessionId)}
            <SavedSessionCard session={plan} />
          {/each}
        </div>
      {/if}

      <div class="sessions-link">
        {#if sessionPlans.length > 0}
          <a
            id="desktop-view-more-session-plans-link"
            href="/sessions/plans/list"
            class="gigxr-link dashboard-view-more-button"
            use:link
          >View More</a>
        {/if}
      </div>
    </PrimaryContent>
  </SecondaryBackgroundWrapper>
</div>

<style>
  .desktop-dashboard {
    display: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  .headset-login {
    text-align: center;
    margin-bottom: 2em;
  }

  .headset-login div a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .recent-sessions {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    justify-items: center;
    margin-bottom: 20px;
    margin-top: 0.75em;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .recent-sessions {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
  }

  @media (min-width: 1100px) {
    .recent-sessions {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }
  }

  .sessions-link {
    text-align: center;
  }

  .mobile-sessions-button {
    text-align: center;
    margin-top: 0.75em;
    margin-bottom: 30px;
  }

  .mobile-sessions-link {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0.75em;
  }

  .dashboard-view-more-button {
    color: #94aabb;
  }

  .sessions-link--available-sessions,
  .sessions-link--saved-sessions {
    min-height: 16px;
    margin-bottom: 2em;
  }

  @media (min-width: 1100px) {
    .desktop-dashboard {
      display: initial;
    }

    .mobile-dashboard {
      display: none;
    }

    h2 {
      margin-bottom: 0;
    }

    .recent-sessions {
      flex-direction: row;
      justify-content: space-between;
    }

    .sessions-link {
      text-align: right;
    }

    .sessions-link--saved-sessions {
      margin-bottom: 1em;
    }
  }

  .mobile-dashboard__headset-qr-wrapper {
    padding: 1rem 0;
    width: 100%;
    display: grid;
    place-items: center;
    background: #fff;
    text-align: center;
  }

  .mobile-dashboard__headset-qr {
    width: 100%;
  }
</style>
