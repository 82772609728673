import { authenticatedGet } from '../api';
import { APPS_MAPPING_BY_ID } from '../client-applications';

/**
 * Fetch a list of versions
 * @returns Array a list of versions
 * @TODO currently, the HoloScenarios clientAppId is baked into this method. Eventually, we need to remove it from here.
 */
async function fetchVersions() {
  let data = [];
  // NOTE: hard-coding the HoloScenarios ClientAppId here.
  // TODO: Please remove when we have a better way to doing this
  const clientAppId = APPS_MAPPING_BY_ID.HOLO_SCENARIOS;
  // NOTE: hard-coding the API version header here
  // TODO: Please remove when we have a better way to doing this
  const headers = {
    'api-version': '1.1',
  };
  const response = await authenticatedGet(`/client-apps/${clientAppId}/versions`, headers);

  // let's not throw an error. Instead, let's do something about it.
  if (!response.ok) {
    //throw new GmsError(`Error fetching versions! ${GENERIC_ERROR_MESSAGE}`);
    data = [];
    return data;
  } else {
    data = await response.json();
    return data.data;
  }

  //return data;
}

export { fetchVersions };
