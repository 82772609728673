<script>
  import LoadingView from '../LoadingView.svelte';

  export let imageSrc = '';
  export let loading = true;
  export let withSession = true;
  export let timer = '';
</script>

<div class="qr-wrapper">
  {#if loading}
    <LoadingView />
  {:else}<img alt="Login QR Code" src={imageSrc} />{/if}
</div>

{#if !withSession}
  <p>Scan the QR Code as prompted on your device to login.</p>
{/if}

<p>Code auto-refreshes after {timer} minutes</p>

<style>
  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 30px;
    min-height: 300px;
  }

  .qr-wrapper img {
    width: 100%;
  }

  @media (min-width: 1100px) {
    .qr-wrapper {
      margin: 0 auto 30px;
      width: 300px;
      height: 300px;
    }

    .qr-wrapper img {
      width: 250px;
    }
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin: 0;
  }
</style>
