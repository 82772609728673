<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Tab, { Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import Button from '@smui/button';
  import Textfield from '@smui/textfield';
  import Select, { Option } from '@smui/select';
  import { getAccountId } from '../../../util/account';
  import { mobileView, title, breadcrumbPaths, snackbar, snackbarMessage } from '../../../stores/core-store';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../../util/dates';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import { fetchModules, findModuleByModuleId } from '../../../util/api/modules';
  import { fetchClasses } from '../../../util/api/classes';
  import FilledTextArea from '../../../components/FilledTextArea.svelte';
  import {
    createSession,
    createSessionPlan,
    editSession,
    fetchSession,
    fetchSessionParticipants,
    sendSessionInvite,
    sendSessionUninvite,
    updateSessionParticipants,
  } from '../../../util/api/sessions';
  import LoadingView from '../../../components/LoadingView.svelte';
  import ReadOnlySessionView from '../../../components/sessions/ReadOnlySessionView.svelte';
  import JoinSessionButton from '../../../components/sessions/JoinSessionButton.svelte';
  import SessionInProgressBanner from '../../../components/sessions/SessionInProgressBanner.svelte';
  import ClientAppLogo from '../../../components/client-apps/ClientAppLogo.svelte';
  import SessionPermissionSelect from '../../../components/sessions/SessionPermissionSelect.svelte';
  import SessionScenesPanel from '../../../components/sessions/SessionScenesPanel.svelte';
  import GigXrDialog from '../../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import SessionParticipantsList from '../../../components/sessions/SessionParticipantsList.svelte';
  import { SessionForbidError } from '../../../util/errors';
  import GigXrDatepicker from '../../../components/GigXrDatepicker.svelte';
  import GigXrTimepicker from '../../../components/GigXrTimepicker.svelte';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import SessionContentManager from '../../../components/sessions/SessionContentManager.svelte';
  import { isHoloChem, isHoloHuman, isHoloScenarios, isLegacyHoloPatient, isUnity } from '../../../util/unity';
  import { deepCopy } from '../../../util/util';
  import CreatePhotonSessionButton from '../../../components/sessions/CreatePhotonSessionButton.svelte';
  import PlatformStagePanel from '../../../components/sessions/PlatformStagePanel.svelte';
  import PlatformV3ContentPanel from '../../../components/sessions/PlatformV3ContentPanel.svelte';
  import { getAccountRole } from '../../../util/account';
  import { AccountRole } from '../../../util/api/accounts';
  import HoloHumanSessionContentManager from '../../../components/sessions/HoloHumanSessionContentManager.svelte';

  export let sessionId;

  title.set('View Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'View Session',
      location: `/sessions/view/${sessionId}`,
    },
  ]);

  let activeTab = 'Details';
  let tabs = ['Details', 'Content'];

  // https://github.com/hperrin/svelte-material-ui/issues/67
  $: tabPromise = Promise.resolve(tabs);
  let clientAppVersion = '';
  let moduleNames = [];
  // TODO: set modulesNames to 'None' as default. Currently, fetchSession() method doesn't come back with ModuleList for sessions
  let modulesNames = null;
  let modules = [];
  let moduleList = [];
  let sessionForbid = false;
  let clientApps = [];
  let classesById = {};
  let sessionParticipants = [];
  let sessionParticipantIds = [];

  let archiveDialog;
  let discardChangesDialog;

  let dateValue;
  let hourValue;
  let minuteValue;

  let canEditSession = false;
  let isStudent = getAccountRole() === AccountRole.STUDENT;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppVersion: '',
    clientAppId: '',
    classId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
  };

  let session = null;

  $: {
    if (session) {
      if (session.moduleList) {
        moduleList = session.moduleList.split(',');
      }

      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = clientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  onMount(async () => {
    try {
      let classes;
      [clientApps, classes, lastSavedSession, sessionParticipants, modules] = await Promise.all([
        fetchClientApps(),
        fetchClasses(),
        fetchSession(sessionId),
        fetchSessionParticipants(sessionId),
        fetchModules(),
      ]);

      classes.forEach((clazz) => (classesById[clazz.classId] = clazz));
      sessionParticipantIds = sessionParticipants.map((sp) => sp.accountId);

      canEditSession = lastSavedSession.createdById === getAccountId();
      if (canEditSession) {
        tabs = ['Details', 'Content'];
      }

      if (isLegacyHoloPatient(lastSavedSession.clientAppId)) {
        lastSavedSession.hmdJson.scenes = lastSavedSession.hmdJson.scenes ?? [];
      }
      classes = classes.filter((classItem) => classItem.classStatus === 'Active');

      session = deepCopy(lastSavedSession);
      loadSessionToUi();
    } catch (error) {
      if (!(error instanceof SessionForbidError)) throw error;
      sessionForbid = true;
    }
  });

  function loadSessionToUi() {
    moduleList.forEach((modId) => {
      console.log(`modId is: ${modId}`);
      let md = findModuleByModuleId(modId, modules);
      if (md && md.moduleName) {
        moduleNames.push(md.moduleName);
      }
      if (moduleNames.length) {
        modulesNames = moduleNames.join(', ');
      }
      console.log(`module is: ${md}`);
    });

    if (typeof session.clientAppVersion != 'undefined' && typeof session.clientAppVersion != 'null') {
      clientAppVersion = session.clientAppVersion;
    } else {
      clientAppVersion = '';
    }

    // cleanup
    if (clientAppVersion === null || clientAppVersion === 'null') {
      clientAppVersion = '';
    }

    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  }

  function discardChanges() {
    session = deepCopy(lastSavedSession);
    loadSessionToUi();
  }

  async function save() {
    // editSession does not support v1.1 API, or updating the version, so the responses will not include version.
    // For now we will populate it from the original version
    let version = lastSavedSession.clientAppVersion;
    lastSavedSession = await editSession(session);
    lastSavedSession.clientAppVersion = version;
    session = deepCopy(lastSavedSession);
    loadSessionToUi();
    snackbarMessage.set('Session saved!');
    $snackbar.open();
  }

  async function saveAndAddToSessionPlans() {
    lastSavedSession = await editSession(session);
    session = deepCopy(lastSavedSession);
    loadSessionToUi();
    const sessionPlan = await createSessionPlan(session, lastSavedSession.sessionId);
    snackbarMessage.set('Session saved and added to session plans!');
    $snackbar.open();
  }

  async function saveAsDuplicate() {
    const sessionCopy = deepCopy(session);
    sessionCopy.sessionName = `${sessionCopy.sessionName} copy`;
    const newSession = await createSession(sessionCopy);
    snackbarMessage.set('Session duplicated!');
    $snackbar.open();

    navigate(`/sessions/view/${newSession.sessionId}`);
    session = newSession;
  }

  async function archiveSession() {
    session.sessionStatus = 'Archived';
    lastSavedSession = await editSession(session);
    session = deepCopy(lastSavedSession);
    loadSessionToUi();
    snackbarMessage.set('Session archived!');
    $snackbar.open();
  }

  async function unarchiveSession() {
    session.sessionStatus = 'Ended';
    lastSavedSession = await editSession(session);
    session = deepCopy(lastSavedSession);
    loadSessionToUi();
    snackbarMessage.set('Session unarchived!');
    $snackbar.open();
  }

  async function saveParticipantsHandler(event) {
    await updateSessionParticipants(sessionId, sessionParticipants);
    snackbarMessage.set('Participant list saved!');
    $snackbar.open();
  }

  async function sendParticipantUninviteHandler(event) {
    await sendSessionUninvite(sessionId, event.detail);
    await updateSessionParticipants(sessionId, sessionParticipants);
    snackbarMessage.set('Participant notified!');
    $snackbar.open();
  }

  async function sendParticipantInviteHandler(event) {
    await sendSessionInvite(sessionId, event.detail.accountId);
    sessionParticipants = sessionParticipants.map((sp) => {
      if (sp.accountId === event.detail.accountId) {
        sp.sessionParticipantStatus = 'Invited';
      }
      return sp;
    });
    await updateSessionParticipants(sessionId, sessionParticipants);
    snackbarMessage.set('Invitation sent!');
    $snackbar.open();
  }
</script>

<PrimaryContent>
  <div class="join-session-button-wrapper">
    <CreatePhotonSessionButton {session} />
    <JoinSessionButton {session} disabled={!session || (session.locked && session.createdById !== getAccountId())} />
  </div>

  {#await tabPromise then tabs}
    <TabBar {tabs} let:tab bind:active={activeTab} class="gigxr-tabs">
      <Tab {tab} minWidth>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>
  {/await}
</PrimaryContent>

<SecondaryBackgroundWrapper>
  {#if sessionForbid}
    <PrimaryContent>
      <div class="session-forbid">
        <h2>You no longer have access to this session.</h2>
        <p>Please contact the session owner if you believe this message is an error.</p>
      </div>
    </PrimaryContent>
  {:else if session}
    {#if session.sessionStatus === 'InProgress'}
      <SessionInProgressBanner />
    {/if}
    <PrimaryContent>
      {#if canEditSession}
        <TwoColumnSection>
          <div slot="left" class="view-session-left-section" hidden={activeTab !== 'Details'}>
            <div class="logo-row">
              <div class="logo-row__logo">
                <ClientAppLogo {clientAppName} clientAppId={session.clientAppId} />
                <input type="hidden" name="clientAppVersion" value={clientAppVersion} disabled="disabled" />
                {#if clientAppVersion !== ''}
                  <div class="gigxr-version-number">ver: {clientAppVersion}</div>
                {/if}
              </div>
              <div class="logo-row__content">
                <ContentRow>
                  <Textfield
                    input$id="session-name-field"
                    class="gigxr-input"
                    bind:value={session.sessionName}
                    variant="filled"
                    label="Session Name"
                  />
                </ContentRow>

                {#if modulesNames}
                  <ContentRow>
                    <Textfield
                      name="no-update-modulenames"
                      class="gigxr-input"
                      value={modulesNames}
                      variant="filled"
                      label="Module"
                      enhanced
                      disabled="disabled"
                    />
                  </ContentRow>
                {/if}

                <ContentRow>
                  <Select
                    inputId="client-app-field"
                    class="gigxr-input"
                    bind:value={session.clientAppId}
                    variant="filled"
                    label="App"
                    enhanced
                    disabled
                  >
                    <Option value="" />
                    {#each clientApps as app (app.clientAppId)}
                      <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                        {app.clientAppName}
                      </Option>
                    {/each}
                  </Select>
                </ContentRow>

                <ContentRow>
                  <Select
                    inputId="class-field"
                    class="gigxr-input"
                    bind:value={session.classId}
                    variant="filled"
                    label="Class"
                    enhanced
                  >
                    <Option value="" />
                    {#each Object.values(classesById) as clazz (clazz.classId)}
                      <Option value={clazz.classId}>{clazz.className}</Option>
                    {/each}
                  </Select>
                </ContentRow>
              </div>
            </div>

            <ContentRow class="session-lesson-row">
              <div class="session-lesson-row__label">Lesson Start Date:</div>
              <GigXrDatepicker id="lesson-start-date-field" bind:dateValue />
            </ContentRow>

            <ContentRow class="session-lesson-row">
              <div class="session-lesson-row__label">Lesson Start Time:</div>
              <GigXrTimepicker bind:hourValue bind:minuteValue />
            </ContentRow>

            <ContentRow>
              <FilledTextArea
                id="session-description-field"
                bind:value={session.description}
                label="Description"
                ariaLabel="session-description"
              />
            </ContentRow>

            <ContentRow>
              <Select
                inputId="notes-visibility-field"
                class="gigxr-input gigxr-input--half-width gigxr-input--left-align"
                bind:value={session.sessionNoteVisible}
                variant="filled"
                label="Notes Visibility"
                enhanced
              >
                <Option value={false}>Private</Option>
                <Option value={true}>Visible</Option>
              </Select>
            </ContentRow>

            <ContentRow>
              <FilledTextArea
                id="session-notes-field"
                bind:value={session.sessionNote}
                label="Notes"
                ariaLabel="session-notes"
              />
            </ContentRow>

            <SessionParticipantsList
              session={lastSavedSession}
              bind:sessionParticipants
              bind:sessionParticipantIds
              on:GigXr:participantAdded={saveParticipantsHandler}
              on:GigXr:participantRemoved={saveParticipantsHandler}
              on:GigXr:participantRemovedAndNotified={sendParticipantUninviteHandler}
              on:GigXr:participantInvited={sendParticipantInviteHandler}
            />
          </div>

          <div slot="left" class="session-content-tab" hidden={activeTab !== 'Content'}>
            {#if isUnity() && session.sessionStatus !== 'InProgress'}
              {#if isLegacyHoloPatient(session.clientAppId)}
                <SessionContentManager session={lastSavedSession} />
              {:else if isHoloHuman(session.clientAppId)}
                <HoloHumanSessionContentManager session={lastSavedSession} />
              {:else}
                <!-- Platform does not require a content manager but a placeholder has been added for future use. -->
                <!-- <PlatformSessionContentManager session={lastSavedSession} /> -->
              {/if}
            {/if}
            <!-- <SessionLabelList bind:hmdJson={session.hmdJson} sessionCreatedById={session.createdById} /> -->
            {#if isLegacyHoloPatient(session.clientAppId)}
              <SessionScenesPanel
                clientAppId={session.clientAppId}
                bind:hmdJson={session.hmdJson}
                sessionCreatedById={session.createdById}
              />
            {:else if isHoloHuman(session.clientAppId)}
              <PlatformStagePanel bind:hmdJson={session.hmdJson} sessionCreatedById={session.createdById} />
            {:else if !(isStudent && isHoloScenarios(session.clientAppId))}
              <PlatformV3ContentPanel
                inputLabel={isHoloChem(session.clientAppId) ? 'Experiment' : 'Scenario'}
                bind:hmdJson={session.hmdJson}
              />
            {:else}
              <!-- Empty fallback block -->
              <!-- TODO: Create default Content Tab -->
            {/if}

            {#if !$mobileView}
              <div class="credits-link">
                <a href="https://www.gigxr.com/credits" target="_blank" rel="noopener noreferrer">CREDITS</a>
              </div>
            {/if}
          </div>

          <div slot="right">
            <SessionPermissionSelect bind:session />

            <DateAccountComponent label="Created" utcDateString={session.createdOn} account={session.createdBy} />

            <DateAccountComponent label="Last Saved" utcDateString={session.modifiedOn} account={session.modifiedBy} />

            {#if $mobileView}
              <div class="credits-link">
                <a href="https://www.gigxr.com/credits" target="_blank" rel="noopener noreferrer">CREDITS</a>
              </div>
            {/if}

            <ContentRowCenteredOnMobile>
              <Button id="edit-session-button" class="gigxr-button" variant="unelevated" on:click={() => save()}>
                <Label>Save Changes</Label>
              </Button>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="session-discard-changes-link"
                class="gigxr-link"
                on:click={() => discardChangesDialog.open()}
              >Discard Changes</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="session-save-add-to-session-plans-link" class="gigxr-link" on:click={saveAndAddToSessionPlans}>Save
                and Add to Session Plans</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="session-save-as-duplicate-link" class="gigxr-link" on:click={saveAsDuplicate}>Save as Duplicate</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              {#if session.sessionStatus === 'Archived'}
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="session-unarchive-link" class="gigxr-link" on:click={unarchiveSession}>Unarchive</a>
              {:else}
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="session-archive-link" class="gigxr-link" on:click={() => archiveDialog.open()}>Archive</a>
              {/if}
            </ContentRowCenteredOnMobile>
          </div>
        </TwoColumnSection>
      {:else}
        <ReadOnlySessionView {activeTab} {session} {clientApps} {classesById} />
      {/if}
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<GigXrDialog bind:dialog={archiveDialog} ariaPrefix="archive-session">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Are you sure you want to archive this session?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={archiveSession}>Archive</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<DiscardChangesDialog
  bind:dialog={discardChangesDialog}
  href="/sessions/{sessionId}"
  clickHandler={() => discardChanges()}
/>

<style>
  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  .logo-row__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .gigxr-version-number {
    margin: 10px auto;
    text-align: center;
  }

  .join-session-button-wrapper {
    text-align: center;
    margin-bottom: 1em;
  }

  .session-content-tab {
    margin-bottom: 1.5em;
  }

  @media (min-width: 1100px) {
    .join-session-button-wrapper {
      position: absolute;
      right: 25px;
      bottom: 0;
      z-index: 1;
      margin-bottom: 0;
    }

    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }

    .session-content-tab {
      margin-bottom: 0;
    }
  }

  .session-content-tab,
  .view-session-left-section {
    margin-bottom: 3em;
  }

  .session-forbid {
    text-align: center;
  }

  .credits-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--gigxr-theme-primary-1c);
    text-decoration: underline;
  }

  @media (min-width: 1100px) {
    .view-session-left-section {
      margin-bottom: 1.5em;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.session-lesson-row) .session-lesson-row__label {
    display: inline-block;
    flex: 1;
    font-weight: 700;
    padding-right: 5px;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>
