<script>
  import LoadingView from '../LoadingView.svelte';

  export let qrCode = '';
  export let loading = true;
  export let withSession = true;
  export let timer = '';

  function formatQRCode(code) {
    const codeString = code.toString();
    const length = codeString.length;

    return codeString.slice(0, length / 2) + '-' + codeString.slice(length / 2);
  }
</script>

<div class="qr-wrapper">
  {#if loading}
    <LoadingView />
  {:else}
    <p class="qr-numeric-code">{formatQRCode(qrCode)}</p>
  {/if}
</div>

{#if !withSession}
  <p>Enter this code in your headset to log in.</p>
{/if}

<p>Code auto-refreshes after {timer} minutes</p>

<style>
  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
  }

  .qr-numeric-code {
    width: 100%;
    font-size: 2rem;
    font-weight: 800;
    margin: 1rem 0;
  }

  p {
    margin: 0;
  }

  @media (min-width: 480px) and (max-width: 1099px) {
    .qr-numeric-code {
      font-size: 4rem;
    }
  }

  @media (min-width: 1100px) {
    .qr-wrapper {
      margin: 0 auto 1.875rem;
    }

    .qr-numeric-code {
      font-size: 6rem;
      font-weight: 700;
      margin: 2rem 0;
    }
  }
</style>
