<script>
  import { onMount } from 'svelte';
  import { generateQrCodeImageData } from '../../util/qr-codes';
  import { title, errorMessage, breadcrumbPaths } from '../../stores/core-store';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';

  title.set('Anchor (Calibration)');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Anchor (Calibration)',
      location: '/calibrate-room',
    },
  ]);

  let canvas;
  let imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  onMount(async () => {
    imageSrc = await generateQrCodeImageData('GIGXR');
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <div class="qr-content">
      <div class="qr-wrapper"><img alt="Login QR Code" src={imageSrc} /></div>

      <h2>Instructions</h2>

      <p>
        Synchronizing all devices using Anchor (Calibration) QR Code will ensure all participants located in the same
        space will see session content in the same place and orientation.
      </p>

      <ol>
        <li>
          Place the QR Code where the host and all participants can easily scan it with their HoloLens headset. Taping
          it to a wall or other vertical structure is best.
        </li>
        <li>All participants entering the app must then scan the QR Code at the same position in the room.</li>
      </ol>

      <p>
        The Content Placeholder will appear at the point where Anchor (Calibration) QR Code was placed. The host of the
        session can then move this Content Placeholder to a more appropriate position to instantiate the content.
      </p>
      <p>
        If during the session the content drifts or changes location unexpectedly for any participant, they should tap
        the Reset Anchor (Calibration) button on their Toolbar in the app and repeat the QR code scan.
      </p>
    </div>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .qr-content {
    text-align: left;
    font-family: 'Lato';
    font-size: large;
    line-height: normal;
    margin: auto;
    width: 100%;
  }
  .qr-content h2 {
    font-weight: 700;
  }
  .qr-content p,
  li {
    font-weight: 500;
  }

  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 30px;
  }

  .qr-wrapper img {
    width: 100%;
  }

  @media (min-width: 1100px) {
    .qr-wrapper {
      margin: 0 auto 30px;
      width: 300px;
      height: 300px;
    }

    .qr-wrapper img {
      width: 250px;
    }
  }
</style>
