<script>
  import { onMount, onDestroy } from 'svelte';
  import { link } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
  import { account, title, breadcrumbPaths } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import {
    sendUnityJoinSessionMessage,
    sendUnityJoinDemoSessionMessage,
    isDeveloper,
    isUnity,
    isHoloScenarios,
    isHoloHumanPlus,
  } from '../../../util/unity';
  import SessionLobbyColocatedButton from '../../../components/sessions/SessionLobbyColocatedButton.svelte';
  import SessionLobbyRemoteButton from '../../../components/sessions/SessionLobbyRemoteButton.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import {
    fetchSession,
    fetchSessionParticipants,
    updateSessionParticipant,
    SessionStatus,
  } from '../../../util/api/sessions';
  import BottomModal, { showBottomModal } from '../../../components/BottomModal.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';
  import { getAccountId } from '../../../util/account';
  import ListPageNoResultsMessage from '../../../components/ListPageNoResultsMessage.svelte';
  import { fetchInstitutionLeaf } from '../../../util/api/institutions';

  export let sessionId;

  title.set('Session Lobby');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'View Session',
      location: `/sessions/view/${sessionId}`,
    },
    {
      name: 'Session Lobby',
      location: `/sessions/lobby/${sessionId}`,
    },
  ]);

  let loading = true;
  let session;
  let sessionParticipants;
  let institutionLeaf;

  /** @type {'selectLocation' | 'colocated' | 'remote'} */
  let pageState = 'selectLocation';

  const nowDate = new Date();
  $: sessionLessonDate = session ? utcStringToLocalDate(session.lessonDate) : '';
  $: sessionApplicationName = session?.clientApp.clientAppName;
  $: clientAppId = session?.clientAppId;

  onMount(async () => {
    let sessionParticipantsData = [];
    [session, sessionParticipantsData, institutionLeaf] = await Promise.all([
      fetchSession(sessionId),
      fetchSessionParticipants(sessionId),
      fetchInstitutionLeaf($account.institutionId),
    ]);

    // Sort before displaying
    sessionParticipantsData.sort(sessionParticipantCompareFunction);
    sessionParticipants = sessionParticipantsData;

    const SESSION_PARTICIPANTS_POLL_DELAY = 5000;
    pollSessionParticipants(SESSION_PARTICIPANTS_POLL_DELAY);

    loading = false;
  });

  let sessionParticipantsTimerId;

  function pollSessionParticipants(delay) {
    sessionParticipantsTimerId = setTimeout(async () => {
      sessionParticipants = (await fetchSessionParticipants(sessionId)).sort(sessionParticipantCompareFunction);
      pollSessionParticipants(delay);
    }, delay);
  }

  let sessionStatusTimerId;

  function pollSessionStatusAndJoinWhenAvailable(delay, pollNow = false) {
    sessionStatusTimerId = setTimeout(
      async () => {
        session = await fetchSession(sessionId);

        if (session.sessionStatus === SessionStatus.IN_PROGRESS) {
          // Session is now in progress, join it!
          sendUnityJoinSessionMessage(session);
        } else {
          // Session is not in progress, check again in a little bit.
          pollSessionStatusAndJoinWhenAvailable(delay);
        }
      },
      pollNow ? 0 : delay,
    );
  }

  function sessionParticipantCompareFunction(a, b) {
    // 1. Return session creator first.
    if (a.accountId === session.createdById) {
      return -1;
    }

    // 2. Then sort alphabetically by first name.
    const aFirstName = a.account.firstName.toLowerCase();
    const bFirstName = b.account.firstName.toLowerCase();
    if (aFirstName < bFirstName) {
      return -1;
    }
    if (aFirstName > bFirstName) {
      return 1;
    }

    return 0;
  }

  async function sessionColocatedButtonClickHandler(event) {
    await updateSessionParticipant(session.sessionId, getAccountId(), 'InSessionColocated');
    joinSessionWhenAvailable();
    pageState = 'colocated';
  }

  async function sessionRemoteButtonClickHandler(event) {
    await updateSessionParticipant(session.sessionId, getAccountId(), 'InSessionRemote');
    joinSessionWhenAvailable();
    pageState = 'remote';
  }

  async function joinSessionWhenAvailable() {
    if (institutionLeaf.canMobileCreateSessions) {
      sendUnityJoinDemoSessionMessage(session);
      return;
    }

    // If in developer mode, and we are the creator of the session, allow to join.
    // People are not usually allowed to start a session from mobile.
    if (isDeveloper() && session.createdById === getAccountId()) {
      sendUnityJoinSessionMessage(session);
      return;
    }

    const SESSION_STATUS_POLL_DELAY = 5000;
    pollSessionStatusAndJoinWhenAvailable(SESSION_STATUS_POLL_DELAY, true);
  }

  onDestroy(() => {
    clearTimeout(sessionParticipantsTimerId);
  });
</script>

<PrimaryContent>
  {#if !loading}
    <div class="session-lobby__header">
      <h2>{session.sessionName}</h2>

      {#if sessionLessonDate > nowDate}
        <p class="session-lobby__upcoming">
          UPCOMING ({sessionLessonDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })})
        </p>
      {/if}

      {#if pageState === 'colocated' || pageState === 'remote'}
        <div class="lobby-waiting">
          <h3>Please wait here.</h3>
          <p>Your session will automatically begin when your host starts it.</p>
        </div>
      {:else}
        <!-- HoloScenarios & HoloHuman+ & Mobile specific view -->
        {#if (clientAppId && (isHoloScenarios(clientAppId) || isHoloHumanPlus(clientAppId) || sessionApplicationName === 'DICOM Library XR')) || isUnity()}
          <p>To enter the session, choose who will control the position of your holograms.</p>
          <ContentRow class="session-lobby__colocated-wrapper">
            <SessionLobbyColocatedButton buttonLabel="Host" on:click={sessionColocatedButtonClickHandler} />
          </ContentRow>
          <p>Best if located with the host.</p>
          <br />
          <ContentRow class="session-lobby__remote-location-wrapper">
            <SessionLobbyRemoteButton buttonLabel="Me" on:click={sessionRemoteButtonClickHandler} />
          </ContentRow>
          <p>Best if working independently.</p>
          <br />
        {:else}
          <!-- HoloPateint / HoloHuman -->
          <p>To enter session, select your location:</p>
          <ContentRow class="session-lobby__colocated-wrapper">
            <SessionLobbyColocatedButton on:click={sessionColocatedButtonClickHandler} />
          </ContentRow>

          <ContentRow class="session-lobby__remote-location-wrapper">
            <SessionLobbyRemoteButton on:click={sessionRemoteButtonClickHandler} />
          </ContentRow>

          <Button id="unsure-location-button" class="session-lobby-link" on:click={() => showBottomModal()}>
            <Label>I'm unsure of my location</Label>
          </Button>
        {/if}

        <div class="session-lobby__last">
          <a
            id="session-lobby-using-headset-link"
            class="gigxr-link session-lobby-link"
            href="/qr/headset-login/?sessionId={sessionId}"
            use:link
          >
            I'm using a headset
          </a>
        </div>
      {/if}
    </div>
  {/if}
</PrimaryContent>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else if sessionParticipants.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no session participants to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <div class="session-lobby__content">Also participating in this session:</div>
      <ul class="session-lobby_participants-list">
        {#each sessionParticipants as sessionParticipant (sessionParticipant.accountId)}
          {#if sessionParticipant.accountId === session.createdById}
            <li class="session-lobby_participants-item">
              <Icon class="material-icons">stars</Icon>
              {sessionParticipant.account.firstName}
              {sessionParticipant.account.lastName.substring(0, 1)}
            </li>
          {:else if sessionParticipant.sessionParticipantStatus === 'InSessionColocated'}
            <li class="session-lobby_participants-item">
              <Icon class="material-icons">person</Icon>
              {sessionParticipant.account.firstName}
              {sessionParticipant.account.lastName.substring(0, 1)}
            </li>
          {:else if sessionParticipant.sessionParticipantStatus === 'InSessionRemote'}
            <li class="session-lobby_participants-item">
              <Icon class="material-icons">language</Icon>
              {sessionParticipant.account.firstName}
              {sessionParticipant.account.lastName.substring(0, 1)}
            </li>
          {:else if sessionParticipant !== 'Removed'}
            <li class="session-lobby_participants-item session-lobby_participants-item--offline">
              <Icon class="material-icons">wifi_off</Icon>
              {sessionParticipant.account.firstName}
              {sessionParticipant.account.lastName.substring(0, 1)}
            </li>
          {/if}
        {/each}
      </ul>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<BottomModal>
  <p class="center">
    You can use the GIGXR app to participate in a session whether or not you are in the room with the instructor and
    other participants.
  </p>
  <p class="center">If you are physically in the room with the instructor, select "In Class".</p>
  <p class="center">If you are anywhere else select "Remote".</p>
  <p class="center">
    After making your selection, follow the instructions to finish setting up your device for the session.
  </p>
</BottomModal>

<style>
  .center {
    text-align: center;
  }

  .session-lobby__header {
    text-align: center;
  }

  .session-lobby__header p {
    margin-bottom: 1em;
  }
  .session-lobby__upcoming {
    margin-top: -1em;
  }

  .session-lobby__content {
    text-align: center;
    font-weight: 700;
  }

  .session-lobby_participants-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25em;
    list-style: none;
    padding-left: 0;
    margin: 1.25em;
  }

  .session-lobby_participants-item {
    display: flex;
  }

  .session-lobby_participants-item:not(.session-lobby_participants-item--offline) {
    font-weight: 700;
  }

  .session-lobby_participants-item :global(.material-icons) {
    margin-right: 10px;
  }

  .session-lobby_participants-item--offline {
    opacity: 0.38;
  }

  :global(.session-lobby__colocated-wrapper .mdc-button__label) {
    font-size: 16px;
  }

  :global(.session-lobby__remote-location-wrapper .mdc-button__label) {
    font-size: 16px;
  }

  .session-lobby__last {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }

  .lobby-waiting {
    margin-top: 4.5em;
    margin-bottom: 7em;
  }

  :global(.session-lobby-link) {
    color: #000 !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
  }

  @media (min-width: 1100px) {
    .session-lobby_participants-list {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
