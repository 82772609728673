<script>
  import { fade } from 'svelte/transition';
  import Select, { Option } from '@smui/select';
  import ContentRow from '../ContentRow.svelte';
  import { isHoloChem } from '../../util/unity';

  export let hmdJson;
  export let inputLabel = 'Scenario';

  const fadeConfig = {
    duration: 200,
  };
</script>

{#if hmdJson?.scenarioName}
  <ContentRow class="session-content-panel">
    <div class="session-content-panel__scenario-name" in:fade|local={fadeConfig}>
      <Select class="gigxr-input" value={hmdJson.scenarioName} variant="filled" label={inputLabel} enhanced disabled>
        <Option value={hmdJson.scenarioName}>{hmdJson.scenarioName}</Option>
      </Select>
    </div>
  </ContentRow>
{/if}
